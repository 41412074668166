import React from 'react';
import styled from 'styled-components';
import { Container, Col } from 'react-materialize';
import { COLOR, LABEL, URL, SCREEN_MEDIA } from '../../utils/constants.js';
import TitleSect from '../atoms/titlesect.jsx';
import Path from '../molecules/path.jsx';
import Row from '../organism/row.jsx';
import Card from '../molecules/card.jsx';
import Banner from '../organism/banner.jsx';
import Button from '../atoms/button.jsx';
import { LabelHarmonyRegular } from '../atoms/label.jsx';

import PathIcPhone from '../../icons/ic_phone.svg';
import PathIcGps from '../../icons/ic_gps.svg';
import PathIcMail from '../../icons/ic_mail.svg';

import { openNewTab } from '../../utils/utils.js';


const CardStyled = styled(Card)`
    height: 230px;
    margin: 20px 0 0 0;
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 200px;
    }
`;

const BodyService = () => {
    return (
        <div>
            <Banner title={LABEL.service}/>

            <Container>
                <Row margin='0 0'>
                    <Path 
                        endpoint={LABEL.service}
                        margin='20px 0' />
                </Row>

                <Row margin='60px 0 0 0'>
                    <TitleSect>Matrícula</TitleSect>
                </Row>

                <Row margin='50px 0 0 0'>
                    <Button
                        bg_color='#9ae84d'
                        margin='auto'
                        height='80px'
                        onClick={() => { openNewTab(URL.whatsapp) }}>
                        <LabelHarmonyRegular
                            text_size='40px'
                            text_color={COLOR.white}>
                            Matricúlate aquí
                        </LabelHarmonyRegular>
                    </Button>
                </Row>

                <Row margin='80px 0 0 0'>
                    <TitleSect>Contáctanos</TitleSect>
                </Row>

                <Row margin='60px 0 0 0'>
                    <Col s={12} m={8} l={6} xl={5} offset='m2 xl1'>
                        <CardStyled
                            ic_path={PathIcPhone}
                            title='Teléfonos'
                            title_separation='15px'
                            descs={[LABEL.contactCellPhone]}
                            alignment='center'
                            />
                    </Col>
                    <Col s={12} m={8} l={6} xl={5} offset='m2'>
                        <CardStyled
                            ic_path={PathIcGps}
                            title='Dirección'
                            title_separation='15px'
                            descs={['Av. Pacífico 578, Nuevo Chimbote (02711) - Perú']}
                            alignment='center'
                            />
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default BodyService;