import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Parallax } from 'react-materialize';
import { COLOR, SIZE, SCREEN_MEDIA } from '../../utils/constants.js';
import { LabelHarmonyRegular, LabelHarmonyLite, LabelSailecLight } from '../atoms/label.jsx';

import PathImg04 from '../../images/img_04.jpg';


const ParallaxStyled = styled(Parallax)`
    width: 100%;
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: -1;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #000000;
        opacity: 0.5;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 300px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 320px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        height: 400px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        height: 400px;
    }
`;

const Title = styled(LabelSailecLight)`
    z-index: 1;
    width: 100%;
`;

const Banner = (props) => {
    return (
        <ParallaxStyled
            className='valign-wrapper'
            image={<img alt="" src={props.img_path ? props.img_path : PathImg04}/>}
            options={{responsiveThreshold: 0}}
            children= {
                <Title
                    text_size='46px'
                    text_color={props.title_color ? props.title_color : COLOR.white}
                    text_align='center'
                    position='relative'>
                    {props.title}
                </Title>
            }
            />
    );
}

Banner.propTypes = {
    img_path: PropTypes.string,
    title: PropTypes.string.isRequired,
    title_color: PropTypes.string
}

export default Banner;